<template>
    <DxToolbar :height="91" class="toolbar px-0 primary--bg">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <div>
                    <slot name="before">
                        <DxButton
                            type="default"
                            styling-mode="text"
                            width="56px"
                            height="56px"
                            icon="mdi mdi-arrow-left"
                            class="ml-2 button-style"
                            @click="$router.back()" />
                        <DxButton
                            type="default"
                            styling-mode="text"
                            width="56px"
                            height="56px"
                            icon="mdi mdi-refresh"
                            class="button-style"
                            @click="$router.go(0)" />
                        <DxButton
                            type="default"
                            styling-mode="text"
                            width="56px"
                            height="56px"
                            icon="mdi mdi-home"
                            class="button-style"
                            @click="$router.push('/')"
                    /></slot>
                </div>
            </template>
        </DxItem>
        <DxItem location="center" locate-in-menu="never">
            <template #default>
                <div>
                    <slot name="center"
                        ><div
                            class="font-regular white--text"
                            style="text-transform: uppercase; font-size: 32px"
                        >
                            {{ title }}
                        </div></slot
                    >
                </div>
            </template>
        </DxItem>
        <DxItem location="after">
            <template #default>
                <div>
                    <slot name="after"
                        ><DxButton
                            text="Trợ giúp"
                            type="default"
                            styling-mode="text"
                            height="56px"
                            icon="mdi mdi-help-circle"
                            :rtlEnabled="true"
                            class="button-style"
                            @click="$store.commit('trangChu/setDialogDangPhatTrien',true)"
                    /></slot>
                </div>
            </template>
        </DxItem>
    </DxToolbar>
</template>
<script>
import { DxButton } from "devextreme-vue";
import { DxItem,DxToolbar } from "devextreme-vue/toolbar";
export default {
    props: {
        title: { type: String, default: null, required: false },
    },
    components: {
        DxToolbar,
        DxItem,
        DxButton,
    },
};
</script>
<style scoped>
::v-deep.button-style i {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    color: white !important;
    line-height: 42px !important;
}
::v-deep.button-style span {
    color: white;
    font-size: 28px;
    font-weight:unset
}
</style>